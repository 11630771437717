import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormatDate from "components/utils/FormatDate";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import EditIcon from "@mui/icons-material/Edit";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextField from "@mui/material/TextField";

import { Formik } from "formik";
import * as yup from "yup";

import { ModalConfirm } from "components/layouts/ModalConfirm";
import { ModalSuccess } from "components/layouts/ModalSuccess";
import { ModalDeleteSuccess } from "components/layouts/ModalDeleteSuccess";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/configureStore";
import {
  getCampaignType,
  createCampaign,
  getCampaignPagination,
  getCountCampaignProcess,
  deleteCampaign,
  downloadExcelCampaign,
  copyCampaign,
} from "../../../redux/handlers/master/campaign";

export function CampaignList() {
  const dispatch = useAppDispatch();
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const campaignReducer = useAppSelector((state) => state.campaignReducer);
  const currentDate = new Date();

  const currentYear = new Date().getFullYear();

  // Start and end dates for the current year
  const startOfYear = new Date(`${currentYear}-01-01T00:00:00.000Z`);
  const endOfYear = new Date(`${currentYear}-12-31T16:59:59.999Z`);

  const [date, setDate] = useState([startOfYear, endOfYear]);

  const [shoplist, setShoplist] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    dispatch(
      getCampaignPagination(date[0], date[1], page, pageSize, filterText)
    );
  }, [date, page, pageSize, filterText, dispatch]);

  useEffect(() => {
    dispatch(getCountCampaignProcess(date[0], date[1], filterText));
  }, [date, page, pageSize, filterText, dispatch]);

  useEffect(() => {
    if (campaignReducer.data != null) {
      const data = campaignReducer.data.data.data;
      setDataShop(data);
      setTotalRows(campaignReducer?.data?.data?.meta?.total);
    }
  }, [campaignReducer]);

  const setDataShop = (data) => {
    if (data && data.length > 0) {
      const shopData = data.map((element) => ({
        id: element.id,
        campaignName: element?.name,
        campaignDateStart: element?.start_date
          ? FormatDate(element?.start_date)
          : "",
        campaignDateEnd: element?.end_date ? FormatDate(element?.end_date) : "",
        couponsGivenOut: element?.couponsGivenOut,
        userUsage: element?.totalCouponUsers,
        couponUsage: element?.couponUsage,
        total: element?.total,
        shops: element?.shopCount ? element?.shopCount : 0,
        totalServiceUsage: element?.totalServiceUsage,
        campaignStatus: element?.campaignStatus,
        is_draft: element?.is_draft ? 1 : 0,
      }));
      setShoplist(shopData);
    } else {
      setShoplist([]);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage.page + 1);
    setPageSize(newPage.pageSize);
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  const copyCampaignSelect = () => {
    if (campaignSelectID.length > 0) {
      dispatch(copyCampaign(campaignSelectID))
        .then((response) => {
          if (response.message === "success") {
            setOpenCopyModal(true);
            setPage(1);
            dispatch(
              getCampaignPagination(date[0], date[1], 1, pageSize, filterText)
            );
          }
        })
        .catch((error) => {
          console.error("Error creating campaign", error);
        });
    }
  };

  const deleteCampaignSelect = () => {
    if (campaignSelectID.length > 0) {
      dispatch(deleteCampaign(campaignSelectID))
        .then((response) => {
          if (response.message === "success") {
            setPage(1);
            dispatch(
              getCampaignPagination(date[0], date[1], 1, pageSize, filterText)
            );
            dispatch(getCountCampaignProcess(date[0], date[1], filterText));
            setOpenConfirm(false);
            setOpenDelModal(true);
          }
        })
        .catch((error) => {
          console.error("Error creating campaign", error);
        });
    }
  };

  const download = () => {
    dispatch(downloadExcelCampaign(date[0], date[1], filterText));
  };

  const [campaignSelectID, setCampaignSelectID] = useState([]);

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">
        จัดการแคมเปญ
      </div>

      <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
        <div className="px-[15px] pt-[15px]">
          <div className="d-flex align-items-center px-4 mb-6 flex-wrap">
            <div className="mr-5">
              <button
                className="btn btn-export-st-1 me-auto"
                onClick={() => download()}
                disabled={totalRows == 0}
              >
                Export
              </button>
            </div>
            <div className="mr-auto w-[300px]">
              <div className="formdaterange">
                <label className="mb-1">เลือกช่วงวันที่</label>
                <DateRangePicker
                  onChange={setDate}
                  value={date}
                  calendarIcon={
                    <CalendarMonthIcon className="text-[#31A7DE]" />
                  }
                  className=""
                />
              </div>
            </div>
            <div className="me-3 ">
              <input
                type="text"
                className="form-control form-st-4 h-[38px] w-[250px]"
                placeholder="ค้นหาชื่อร้าน"
                onKeyUp={handleSearch}
              />
            </div>
          </div>
          <div className="flex items-center mb-5 px-4">
            <div>
              <Button
                to="/manage/shop/0/information"
                className="btn pr-3  h-[38px] text-white flex items-center mr-7 bg-[#31A7DE]"
                onClick={() => setOpen(true)}
              >
                <AddIcon className="mr-1" /> สร้างแคมเปญ
              </Button>
            </div>
            <div className="flex items-center">
              <CountCampaignProcess
                startDate={date[0]}
                endDate={date[1]}
                filterText={filterText}
              />
            </div>
            <Button
              disabled={!campaignSelectID.length > 0}
              onClick={() => setOpenConfirm(true)}
              variant="outlined"
              startIcon={<DeleteForeverIcon />}
              sx={{
                "&.Mui-disabled": {
                  background: "#F2F2F2",
                  color: "#6E7F96",
                  border: 0,
                },
              }}
              className="mx-5"
            >
              ลบ
            </Button>
            <Button
              variant="outlined"
              onClick={() => copyCampaignSelect()}
              startIcon={<ContentCopyIcon />}
              disabled={!campaignSelectID.length > 0}
              sx={{
                "&.Mui-disabled": {
                  background: "#F2F2F2",
                  color: "#6E7F96",
                  border: 0,
                },
              }}
            >
              คัดลอก
            </Button>
          </div>
        </div>

        <div>
          <DataGrid
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            rows={shoplist}
            disableColumnMenu
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }}
            checkboxSelection
            disableRowSelectionOnClick
            rowCount={totalRows}
            paginationModel={{ page: page - 1, pageSize: pageSize }}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            pageSizeOptions={[5, 20, 30]}
            slotProps={{
              pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
            }}
            sx={{
              borderRadius: 0,
              ".MuiDataGrid-topContainer": {
                fontSize: "16px",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "16px",
                border: 1,
                borderRight: 0,
                borderTop: 0,
                borderColor: "#D0D7E8",
              },
              "& .MuiDataGrid-columnSeparator": {
                borderRight: "1px solid #D0D7E8",
                right: "-1px",
                width: "5px",
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
            }}
            onRowSelectionModelChange={(ids) => {
              setCampaignSelectID(ids);
            }}
          />
        </div>
      </div>
      <ModalCreate open={open} setOpen={setOpen} />
      <ModalSuccess setOpen={setOpenCopyModal} open={openCopyModal} />
      <ModalDeleteSuccess setOpen={setOpenDelModal} open={openDelModal} />
      <ModalConfirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        message={"คุณต้องการลบข้อมูลนี้ใช่หรือไม่"}
        confirm={() => deleteCampaignSelect()}
      />
    </>
  );
}

const CountCampaignProcess = () => {
  const countCampaignProcess = useAppSelector(
    (state) => state.campaignReducer.count
  );
  return (
    <>
      <CircleIcon className="text-[#00BC3A] text-[7px] mr-4 animate-ping opacity-75" />{" "}
      {countCampaignProcess} แคมเปญกำลังใช้งานอยู่
    </>
  );
};

const columns = [
  {
    field: "campaignName",
    headerName: "ชื่อแคมเปญ",
    flex: 1,
    width: 150,
    minWidth: 250,
    sortable: false,
    editable: false,
    renderCell: ({ row }) => (
      <div>
        <div
          className={` ${
            row.is_draft != 1 ? "text-[#3FA5FF]" : "text-[#8E98A8]"
          } font-bold`}
        >
          {row.campaignName}
        </div>
        <Button
          href={`/campaign/manage/${row.id}`}
          className="text-[#C4C4C4] text-xs px-0 py-1"
          startIcon={<EditIcon className="text-xs" />}
        >
          แก้ไข
        </Button>
      </div>
    ),
    renderHeader: () => (
      <div className="text-[#31A7DE] font-bold">{"ชื่อแคมเปญ "}</div>
    ),
  },
  {
    field: "campaignDateStart",
    headerName: "ระยะเวลาเริ่มต้น",
    headerClassName: "bg-[#FFF]",
    width: 140,
    sortable: false,
    editable: false,
    renderCell: ({ row }) => (
      <div>
        <div className="text-[#242F48] mb-1">{row.campaignDateStart}</div>
        {row.campaignStatus != null ? (
          row.campaignStatus == "PROCESS" ? (
            <div className="text-[10px] text-[#C4C4C4] flex items-center">
              <CircleIcon className="text-[#00BC3A] text-[4px] mr-2 animate-ping opacity-75" />{" "}
              กำลังใช้งาน
            </div>
          ) : row.campaignStatus == "EXPIRE" ? (
            <div className="text-[10px] text-[#FF0000] bg-[#F2F2F2] inline-block rounded p-1 text-center  ">
              ครบกำหนดแล้ว
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    ),
    renderHeader: () => (
      <div className="text-[#31A7DE] font-bold">{"ระยะเวลาเริ่มต้น "}</div>
    ),
  },
  {
    field: "campaignDateEnd",
    headerName: "ระยะเวลาสิ้นสุด",
    width: 140,
    sortable: false,
    editable: false,
    renderCell: ({ row }) => (
      <div>
        <div className="text-[#242F48]">{row.campaignDateEnd}</div>
        <div className=""></div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-[#31A7DE] font-bold">{"ระยะเวลาสิ้นสุด "}</div>
    ),
  },
  {
    field: "couponsGivenOut",
    headerName: "จำนวนคูปองที่แจก",
    sortable: false,
    width: 160,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.couponsGivenOut}</div>
        <div className="text-sm text-[#6E7F96]">ใบ</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">จำนวนคูปองที่แจก</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          คูปองที่แจกให้นักท่องเที่ยว
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "userUsage",
    headerName: "จำนวนผู้ใช้งาน",
    sortable: false,
    width: 160,
    center: false,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.userUsage}</div>
        <div className="text-sm text-[#6E7F96]">คน</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">จำนวนผู้ใช้งาน</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          จำนวนผู้เข้าร่วมแคมเปญ
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "couponUsage",
    headerName: "จำนวนคูปองที่ถูกใช้",
    sortable: false,
    width: 160,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.couponUsage}</div>
        <div className="text-sm text-[#6E7F96]">ใบ</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">จำนวนคูปองที่ถูกใช้</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          จำนวนของคูปองที่ถูกใช้งาน
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "total",
    headerName: "ยอดรวม",
    sortable: false,
    width: 140,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.total}</div>
        <div className="text-sm text-[#6E7F96]">บาท</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">ยอดรวม</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          จำนวนรวมมูลค่าคูปองที่ใช้
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "shops",
    headerName: "จำนวนร้านค้า/สถานที่",
    sortable: false,
    width: 160,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.shops}</div>
        <div className="text-sm text-[#6E7F96]">ร้าน</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">จำนวนร้านค้า/สถานที่</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          จำนวนร้านค้า/สถานที่ที่เข้าร่วมโครงการ
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "totalServiceUsage",
    headerName: "ยอดรวมการใช้บริการ",
    sortable: false,
    width: 170,
    renderCell: ({ row }) => (
      <div>
        <div className="mb-1">{row.totalServiceUsage}</div>
        <div className="text-sm text-[#6E7F96]">บาท</div>
      </div>
    ),
    renderHeader: () => (
      <div className="text-right">
        <div className="text-[#31A7DE] font-bold">ยอดรวมการใช้บริการ</div>
        <div className="text-[10px] text-right text-[#6E7F96]">
          ยอดค่าใช้จ่ายที่นักท่องเที่ยวมาใช้จ่าย
        </div>
      </div>
    ),
    align: "right",
    headerAlign: "right",
  },
];

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/campaign">
    แคมเปญ
  </Link>,
  <Typography key="3" sx={{ color: "text.primary" }}>
    จัดการแคมเปญ
  </Typography>,
];

const ModalCreate = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const [campaignTypeList, setCampaignTypeList] = useState([]);
  useEffect(() => {
    dispatch(getCampaignType());
  }, []);

  const campaignReducer = useAppSelector((state) => state.campaignReducer);

  useEffect(() => {
    if (campaignReducer.campaignType) {
      setCampaignTypeList(campaignReducer.campaignType.data);
    }
  }, [campaignReducer]);

  let navigate = useNavigate();

  const handleClose = () => setOpen(false);
  const [formdata, setFormdata] = useState({
    campaignName: "",
    date: "",
    campaignType: "",
  });

  const schema = yup.object().shape({
    campaignName: yup.string().required(),
    date: yup.array().required(),
    campaignType: yup.string().required(),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("name", values.campaignName);
      formData.append("campaign_type_id", values.campaignType);
      formData.append("start_date", values.date[0]);
      formData.append("end_date", values.date[1]);

      dispatch(createCampaign(formData))
        .then((response) => {
          if (response.message == "success") {
            const newCampaignId = response.data.id;
            navigate(`/campaign/manage/${newCampaignId}`);
          }
        })
        .catch((error) => {
          console.error("Error creating campaign", error);
        })
        .finally(() => {
          setSubmitting(false);
          setOpen(false);
        });
    } catch (error) {
      console.error("Error submitting the form", error);
    } finally {
      setSubmitting(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="bg-[#31A7DE] flex items-center w-full">
          <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo">
            สร้างโปรโมชั่น
          </div>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseSharpIcon className="text-white" />
          </IconButton>
        </div>
        <Formik
          initialValues={formdata}
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="px-7 mt-4  mb-3 py-6">
                <div className="row">
                  <div className="col-4">
                    <TextField
                      label="ชื่อแคมเปญ"
                      name="campaignName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.campaignName}
                      error={!!errors.campaignName}
                      className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                    />
                  </div>
                  <div className="col-4">
                    <div className="formdaterange">
                      <label className="mb-1">เลือกช่วงวันที่</label>
                      <DateRangePicker
                        onChange={(value) => {
                          setFieldValue("date", value);
                        }}
                        value={values.date}
                        calendarIcon={
                          <CalendarMonthIcon className="text-[#31A7DE]" />
                        }
                        className={`daterang-lg w-full ${
                          !!errors.date ? "error" : ""
                        } `}
                        name="date"
                        error={!!errors.date}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="divide-x-2 divide-solid mb-6  h-[1px] border-t-[1px]"></div>
              <div className="px-7 mb-7 ">
                <div className="text-lg text-[#4C4E64] mb-7">
                  เลือกรูปแบบสร้างโปรโมชั่น
                </div>
                <div
                  className={` ${
                    !!errors.campaignType
                      ? "border-[1px] border-[#d32f2f] p-7"
                      : ""
                  } `}
                >
                  <ToggleButtonGroup
                    value={values.campaignType}
                    exclusive
                    onChange={(event, newAlignment) => {
                      setFieldValue("campaignType", newAlignment);
                    }}
                    className="flex justify-between"
                  >
                    {campaignTypeList &&
                      campaignTypeList.map((item, index) => (
                        <ToggleButton
                          value={item.id}
                          key={index}
                          className="w-[30%] bg-[#FAFAFA] rounded-lg border-0 promotion-select-c"
                        >
                          <div className="text-center w-full py-5">
                            <img
                              src={
                                item.name == "รับจุด A ใช้ จุด B"
                                  ? "/images/campaign1.png"
                                  : item.name == "รับจุด A ใช้ จุด A"
                                  ? "/images/campaign2.png"
                                  : "/images/campaign3.png"
                              }
                              className={
                                item.name == "รับจุด A ใช้ จุด B"
                                  ? "w-[230px] mx-auto mb-7"
                                  : item.name == "รับจุด A ใช้ จุด A"
                                  ? "w-[150px] mx-auto mb-7"
                                  : "w-[80px] mx-auto mb-7"
                              }
                            />
                            <div className="px-4">
                              <div className="text-left text-[#31A7DE] font-bold">
                                {item.name}
                              </div>
                              <div className="text-left text-[#000000] text-[12px]">
                                <b>ตัวอย่าง</b>{" "}
                                {item.name == "รับจุด A ใช้ จุด B"
                                  ? "รับคูปองส่วนลดที่โรงแรม ใช้ส่วนลดที่ร้านอาหาร"
                                  : item.name == "รับจุด A ใช้ จุด A"
                                  ? "เมื่อเข้าพักที่โรงแรมรับสิทธิ์ส่วนลดทันที ใช้ส่วนลดที่ร้านอาหาร"
                                  : "เช็คอินที่ ร้านโรงคั่วกาแฟ รับส่วนลด 5 บาท"}
                              </div>
                            </div>
                          </div>
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </div>
              </div>
              <div className="px-7  pt-5 ">
                <div className="flex justify-end mb-7">
                  <Button
                    variant="outlined"
                    className="w-[91px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[38px]"
                    onClick={() => setOpen(false)}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    className="w-[91px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                    type="submit"
                  >
                    บันทึก
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: 0,
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: "hidden",
};
